import { useState, useEffect, useMemo } from 'react';
import { useLanguageQuery } from 'next-export-i18n';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';

import TransactionService from '@/services/transaction';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { commafy, getCurrencyWithAmount, getGstType, shouldCommafy } from '@/common/utility';
import { IPaymentDetailsResponse } from '@/components/InvoiceTable/types';
import InvoiceTable from '@/components/InvoiceTable';
import { MultiLocale } from '@/components/TicketModal/utils';
import { Section } from '@/components/common';

import { IRestaurant } from '@/views/OrdersTableView/types';
import { IPayment } from '@/views/Payments';
import { IPaymentTransaction } from '@/views/Orders/types';
import { transformOrder } from '@/views/Orders';
import { getDateAndTime } from '@/components/InvoiceTable/utils';

import styles from './index.module.scss';
import { parsePaymentDetails } from '../InvoiceTable/transformer';

interface IProps {
    initReceipt?: IPaymentDetailsResponse | null;
    payment?: IPayment | null;
    vendor?: IRestaurant | null;
    currencySymbol: string;
    currencyCode: string;
    qrFile?: File | null;
    preview?: boolean;
    innerRef?: any;
}

export default function ReceiptV2({
    initReceipt,
    payment,
    vendor,
    currencySymbol,
    currencyCode,
    qrFile,
    preview,
    innerRef,
}: IProps) {
    const transactionService = TransactionService.getInstance();
    const { t } = useTranslation('common');
    const [transaction, setTransaction] = useState<IPaymentTransaction | null>(null);
    const [receipt, setReceipt] = useState<IPaymentDetailsResponse | null>(null);
    const [qrImg, setQrImg] = useState('');
    const [loading, setLoading] = useState(true);
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const { restaurant } = useRestaurantContext();

    useEffect(() => {
        if (!vendor || (!payment && !initReceipt)) {
            return;
        }

        const transactionId = initReceipt?.dsi || payment?.transactionId || '';
        const paymentRecordId = payment?.paymentRecordId || '';

        const getReceipt = (): Promise<IPaymentDetailsResponse> => {
            if (initReceipt) {
                return Promise.resolve(initReceipt);
            }
            return transactionService.getReceiptData(vendor.id, transactionId, paymentRecordId);
        };

        setLoading(true);
        Promise.all([transactionService.getTransaction(vendor.id, transactionId), getReceipt()])
            .then(([transactionRes, receiptRes]) => {
                setTransaction(transformOrder(transactionRes.data.data, lang, currencyCode, currencySymbol));
                setReceipt(receiptRes);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [vendor, payment]);

    const receiptLayout = useMemo(() => {
        const layout: any = {};
        if (!restaurant) {
            return layout;
        }

        const itemLayout = restaurant?.posAccess?.pos_vendor_data.itemLayout;
        const billLayout = restaurant?.posAccess?.pos_vendor_data.billLayout;

        if (itemLayout) {
            layout.itemLayout = JSON.parse(itemLayout || '[]');
        }

        if (billLayout) {
            layout.billLayout = JSON.parse(billLayout || '[]');
        }

        return layout;
    }, [restaurant]);

    useEffect(() => {
        if (!qrFile) {
            return () => {
                //
            };
        }

        const url = URL.createObjectURL(qrFile);
        setQrImg(url);
        return () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 10000);
        };
    }, [qrFile]);

    const card = () => {
        const logo = () => {
            const cardBrand = payment?.cardBrand?.toLowerCase();
            // switch (details?.card?.cardBrand?.toLowerCase()) {
            switch (cardBrand) {
                case 'visa':
                    return 'https://app.qlub.io/icons/email/visa@2x.png';
                case 'amex':
                case 'americanexpress':
                    return 'https://app.qlub.io/icons/email/americanexpress@2x.png';
                case 'mastercard':
                    return 'https://app.qlub.io/icons/email/mastercard@2x.png';
                default:
                    return false;
            }
        };

        const cardBrand = (str?: string) => {
            if (!str) {
                return '';
            }

            return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
        };

        const imgUrl = logo();

        return (
            <div
                style={{
                    margin: 0,
                    lineHeight: '24px',
                    display: 'flex',
                }}
            >
                {imgUrl && (
                    <div style={{ display: 'inline-flex', margin: '0 4px 0 0', height: '24px', width: '24px' }}>
                        <img
                            src={imgUrl}
                            alt=""
                            style={{
                                height: '24px',
                                width: '24px',
                                objectFit: 'contain',
                                margin: 0,
                                marginRight: '2,5px',
                            }}
                        />
                    </div>
                )}
                <span style={{ lineHeight: '24px' }}>{cardBrand(payment?.cardBrand?.toLowerCase() || '')}</span>
            </div>
        );
    };

    if (!transaction || !receipt) {
        if (loading) {
            return (
                <div className={styles.loading}>
                    <CircularProgress color="primary" size={24} />
                </div>
            );
        }
        return null;
    }

    return (
        <div className={classNames(styles.receipt, { [styles.visible]: preview })} ref={innerRef}>
            <div className={styles.container}>
                <header className={styles.header}>
                    {(vendor?.logo_big_url || vendor?.logo_small_url) && (
                        <div className={styles.image}>
                            <img
                                src={vendor?.logo_big_url || vendor?.logo_small_url || ''}
                                alt="logo"
                                className={styles.logo}
                            />
                        </div>
                    )}
                    <h1 className={styles.title}>{vendor?.name || vendor?.title}</h1>
                    <p
                        style={{
                            margin: 0,
                            padding: 0,
                            fontFamily: 'inherit',
                            fontWeight: '400',
                            color: '#616475',
                            fontSize: '14px',
                            lineHeight: '20.44px',
                            paddingTop: '32px',
                            textAlign: 'center',
                        }}
                    >
                        {`${vendor?.address1} ${vendor?.address2}`}
                        <br />
                        <MultiLocale value={vendor?.config?.legalBusinessName || ''} lang={lang} />
                        <br />
                        {vendor?.config?.vatRegistrationNumber &&
                            t(`${getGstType(vendor?.country_code)}: {{vatRegistrationNumber}}`, {
                                vatRegistrationNumber: vendor?.config?.vatRegistrationNumber || '',
                            })}
                    </p>
                    <p className={styles.subTitle}>
                        <br />
                        {restaurant?.restaurant_country?.config.receiptTitle || 'Tax Invoice'}
                    </p>
                    <p className={styles.orderId}>
                        {t('Check {{orderId}}', {
                            orderId: transaction?.order_id.split('-')[0] || '',
                        })}
                    </p>
                </header>
                <div className={styles.divider} />
            </div>
            <Section sx={{ border: 'none', padding: 0 }}>
                <InvoiceTable
                    details={parsePaymentDetails(
                        receipt,
                        receiptLayout.billLayout?.length > 0 || receiptLayout.itemLayout?.length > 0
                            ? {
                                  cc: restaurant?.country_code || '',
                                  vendor: restaurant,
                              }
                            : undefined,
                    )}
                    lang={lang}
                    table={transaction.table_data}
                    transaction={transaction}
                />
            </Section>
            <div className={styles.divider1} />
            <div className={styles.payInfo}>
                {Number(payment?.netTipAmount) > 0 && (
                    <>
                        <div className={styles.tip}>
                            <span
                                style={{
                                    margin: 0,
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    lineHeight: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#1C1B1F',
                                }}
                            >
                                {t('Paid tip amount')}
                            </span>
                            <div
                                style={{
                                    width: 'fit-content',
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    color: '#1A1C23',
                                }}
                            >
                                <span>
                                    {getCurrencyWithAmount(
                                        payment?.receiptTipAmount,
                                        currencySymbol,
                                        currencyCode,
                                        true,
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={styles.divider1} />
                    </>
                )}
                {payment?.paidAmount && (
                    <div className={styles.card}>
                        <span className={styles.paidWith}>{t('Paid with')}</span>
                        <div className={styles.cardInfo}>
                            <span>{card()}</span>
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginRight: '2px' }}>{currencySymbol}</span>
                                <span>
                                    {shouldCommafy(currencyCode) ? commafy(payment?.totalAmount) : payment?.totalAmount}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {payment?.paid && (
                    <div className={styles.fullyPaid}>
                        <span>{t('Check Closed')}</span>
                        <span>{getDateAndTime(payment.date, lang)}</span>
                    </div>
                )}
            </div>
            <div className={styles.divider2} />
            {vendor?.config?.receiptBannerTitle || vendor?.config?.receiptBannerDescription ? (
                <div className={styles.footer}>
                    <div className={styles.content}>
                        <h1 className={styles.title}>
                            <MultiLocale value={vendor?.config?.receiptBannerTitle || ''} lang={lang} />
                        </h1>
                        <p className={styles.description}>
                            <MultiLocale value={vendor?.config?.receiptBannerDescription || ''} lang={lang} />
                        </p>
                    </div>
                    <img src={'https://app.qlub.io/icons/email/footer-img@2x.png'} className={styles.image} alt="#" />
                </div>
            ) : null}
            {qrImg && (
                <div className={styles.qr}>
                    <img src={qrImg} alt="qr" />
                </div>
            )}
        </div>
    );
}
